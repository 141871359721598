/*
  Instrucciones para mejorar el SCSS a futuro
    - Volver todos los mixins globales
    - Sustituir colores hexadecimales por variables de sass globales
*/
@mixin flex-center-center {
  display: flex;
  justify-content:center;
  align-items: center;
}
@mixin flex-center-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button-container {
  @include flex-center-end;
  width: 100%;
  height: 50px;

  .button {
    @include flex-center-center;
    min-width: 170px;
    height: 100%;
    background: #EDC729;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-right: 5px;
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
    color: black;
  }
}