/*
  Instrucciones para mejorar el SCSS a futuro
    - Volver todos los mixins globales
    - Sustituir colores hexadecimales por variables de sass globales
*/
@mixin flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@mixin flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}


.table-details{
  width: 55%;
  height: auto;
  background: white;
  color: #4E4E4E;

  &__header {
    width: 100%;
    height: 50px;
    background: #EDC729;
  }

  &__content {
    width: 100%;
    height: auto;
    color: #838383;
  }

  &__title {
    // @include flex-start-center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background: #FDF2BA;
    padding: 0px 25px;
    font-weight: 600;
    text-transform: uppercase;
    align-items: center;
  }
  &__title a {
    color: #838383;
    text-transform: capitalize;
    font-weight: 500;
  }
  &__status {
    @include flex-center-center;
    width: 100%;
    height: 150px;
    background: #F8F8F8;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
  }

  .row {
    @include flex-between-center;
    width: 100%;
    height: 50px;
    
    &__column {
      @include flex-start-center;
      height: 100%;
      padding: 0px 25px;

      &--header-left {
        width: 50%;
      }
      &--header-right {
        justify-content: flex-end;
        width: 50%;
      }
      &--two-column{
        width: 50%;
      }
      &--three-column{
        width: 33%;
      }
      &--four-column{
        width: 25%;
      }
      &--bold {
        font-weight: 600;
      }
      &--center{
        display: flex;
        justify-content: center;
        width: 100%;

        a{
          color: inherit;
          font-weight: 600;
        }
      }
      &--bg-blue{
        background-color: #E8F6FB;
      }
    }
  }
}