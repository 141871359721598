.dropdown-menu-container {
    position: relative;
    display: none;
    /*
    background-color: rgba(0, 0, 0, 0.186);
    top: 0;
    width: 100vw;
    height: 100vh;*/

    &-show {
        display: block;
    }

    .dropdown-menu {
        position: absolute;
        width: 200px;
        right: 0px;
        top: 5px;
        background: white;
        box-shadow: 0px 4px 16px rgb(0 0 0 / 15%);
        border-radius: 16px;

        .option-menu {
            width: 100%;
            height: 50px;
            background: #EDC729;
            color: #121212;
            font-weight: bold;
            border: none;
            border-radius: 16px;
            border-bottom: 1px solid #CCCCCC;
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:last-of-type {
                border-bottom: none;
            }
            img {
                margin-right:1rem;
            }
        }

    }
}