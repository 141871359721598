/*
  Instrucciones para mejorar el SCSS a futuro
    - Volver todos los mixins globales
    - Sustituir colores hexadecimales por variables de sass globales
*/
@mixin flex-start-center {
  display: flex;
  justify-content:flex-start;
  align-items: center;
}
@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table {
  min-width: 45%;
  height: auto;

  &__header {
    width: 100%;
    height: 50px;
    background-color: #F8F8F8; 
  }

  &__body {
    width: 100%;
    height: auto;
    background: #F8F8F8;
  }


  .row {
    @include flex-between-center;
    width: 100%;
    height: 50px;
    &--selected{
      background: #EDC729 !important;
    }
    &__column {
      @include flex-start-center;
      width: 33%;
      height: 100%;
      padding: 10px;
      justify-content: center;
      text-align: center;
      
      &:last-of-type{
        padding-left: 2rem;
      }

      &--position-relative{
        position: relative;
      }

      &--bold {
        font-weight: 700;
      }
      &--grey-header {
        color: #4E4E4E;
      }
      &--grey-body {
        color: #838383;
      }
    }
  }
}

.table__body > .row:nth-child(odd) {
  background: #CCCCCC;
}