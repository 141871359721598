.graficas-estadisticas-wrapper{
    width: 100%;
    padding: 0 10px;

    .bg-color-white {
        background-color: white;
        border-radius: 16px;
        h3 {
            padding: 1.5rem 0 0  1.5rem;
        }
    }

    .graficas-estadisticas{
        
        width: 100%;
        display: flex;
        // margin-top: 1rem;
        .grafica{
            padding: 10px;
            width: 50%;
            max-height: 50vh;
            position: relative;
            
            &__header {
                display: flex;
                flex-direction: column;
                align-items: center;
                h3, div {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                }
                h3 {
                    margin-bottom: 16px;
                }
                div {
                    background-color:  #EDEDED;
                    border-radius: 16px;
                    padding: 5px 20px;
                }
            }
        }
        .grafica-unica {
            width: 100%;
            padding: 0 2rem;
            position: relative;
        }
    }
    .button-wrapper{
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        button {
            font-family: 'Montserrat', sans-serif;
            padding: 10px 30px;
            font-size:13px;
            font-weight: 700;
            line-height:40px;
            text-align: center;
            color:#121212;
            background-color: #EDC729;
            border-radius: 16px;
            border:0;
            cursor: pointer;
        }
    }
}