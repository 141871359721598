.login-header {
    background-color: #EDC729;
    width:100%;
    height:5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
        width: 5.875rem;
        height: 1.813rem;
    }
}

.login-main {
    
    display: flex;
    justify-content: space-between;

    .lap-container {
        background:linear-gradient(90deg, #EBCA00 0%, #FFAD01 100%);
        display:flex;
        flex-direction: column;
        position:relative;
        justify-content:center;
        align-items: center;
    }

    .form-container {
        width: 100%;

        .header-title{
            text-align: center;
            margin: 5rem 0 8rem 0;
            font-family: 'Montserrat', sans-serif;
        }

        .form {
            display: flex;
            flex-direction: column;
            align-items: center;            
            
            .input {
                width: 20rem;
                margin: 16px 0;
                background-color: #EDEDED;
                height: 3rem;
                border-radius: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 16px;

                input {
                    background-color: inherit;
                    border: none;
                    font-size: 13px;
                    font-family: 'Montserrat', sans-serif;
                    width: 100%;

                    &:focus-visible  {
                        outline:none;
                    }
                }
                
                img {
                    max-width: 100%;
                }

                button {
                    width: 1.3rem;
                    background: none;
                    border: none;
                    display: flex;
                    align-items: center;
                }
            }

            .button {
                border: none;
                border-radius: 16px;
                padding: 16px 56px;
                margin-top: 16px;
                background-color: #EDC729;
                cursor: pointer;
             
                &:hover{
                    font-weight: 700;
                    font-size: large;
                }
            }

       
            
        }
    }

    
   
}