#asociado{
    .asociado-selected {
        color: #0582D8;
    }
    .par {
        background: none;
        border-bottom: 2px solid rgba(131,131,131,0.2); 
    }
    .row-nopar {
        border-bottom: 2px solid rgba(131,131,131,0.2);    
        background-color: white;
    }
    .recibo {
        font-weight: 400;
    }
    .btn-ok{
        background-color: #ffee9f;
        p{
            color: #919191;
        }
    }
}