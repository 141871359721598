#admin-ventas {
    .button-wrapper{
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        button {
            font-family: 'Montserrat', sans-serif;
            padding: 10px 30px;
            font-size:13px;
            font-weight: 700;
            line-height:40px;
            text-align: center;
            color:#121212;
            background-color: #EDC729;
            border-radius: 16px;
            border:0;
            cursor: pointer;
        }
    }
}