.modal-estadisticas-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1212127e;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .modal-estadisticas{
        background-color: white;
        width: 60%;
        height: 60%;
        border-radius: 16px;
        padding: 32px;
        position: relative;

        .close-button{
            position: absolute;
            top: -10px;
            right: -10px;
            color: #4E4E4E;
            font-weight: 700;
            background: white;
            border: 1px solid #4E4E4E;
            border-radius: 50%;
            padding: 10px;
            height: 31.5px;
            width: 31.5px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__header{
            color: #4E4E4E;
            margin-bottom: 24px;

            h3{
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
            }
        }

        &__footer{
            display: flex;
            justify-content: flex-end;
            gap: 23px;
            div{
                display: flex;
                align-items: center;
                h3{
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    color: #4E4E4E;
                }
            }

            button{
                border: none;
                background-color: white;
                cursor: pointer;
            }
        }

        &__body{
            min-height: 75%;
            max-height: 75%;
            .modal-estadisticas-table{
                border-collapse:collapse;
                width: 100%;
                display: flex;
                flex-direction: column;
                border: 1px solid #EDEDED;

                &__header, &__body{
                    display: flex;
                    justify-content: space-between;
                }

                th,td{
                    font-family: 'Montserrat', sans-serif;
                    width: 20%;
                    text-align: center;
                    padding: 16px;
                    font-weight: 600;
                    font-size: 14px;
                }

                &__header{
                    padding: 10px;
                    background-color: #EDC729;
                    color: #4E4E4E;

                    th{
                        font-weight: 600;
                    }
                }

                &__body{
                    

                    td{
                        border-bottom:1px solid #CCCCCC;
                        color: #838383;
                        
                    }

                    &--gray{
                        background-color: #EDEDED;
                    }
                    
                }
            }
        }
    }
}