#modal-ordenar {
    
    button {
        padding: 1rem;
        padding-bottom: .5rem;
        background: none;
        border: none;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        color:#4E4E4E;
        width: 100%;
        display:flex;
        justify-content: space-between;
    }
}