#encabezado-graficas{
    padding-left: 10px;
    table {
        width: 100%;
    }
    .header-filtros, .campos-filtros{
        display: flex;
        justify-content: space-around;
        padding: 10px;
        font-size: 14px;
    }
    .header-filtros{
        background-color:#EDC729;
    }
    .campos-filtros{
        background-color: #EDEDED;
        td{
            width: 33.3%;
            text-align: center;
        }
    }
}