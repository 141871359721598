.buscador {
    position: relative;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 1s;
    width: 120px;
    height: 50px;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 5px;
}

.buscador input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    ;
    height: 42.5px;
    line-height: 30px;
    outline: 0;
    border: 0;
    display: none;
    font-size: 1em;
    border-radius: 20px;
    padding: 0 20px;
}

.fa {
    box-sizing: border-box;
    padding: 10px;
    width: 42.5px;
    height: 42.5px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    color: #4E4E4E;
    text-align: center;
    font-size: 1.2em;
    transition: all 1s;
}

.buscador:hover {
    width: 240px;
    cursor: pointer;
}

.buscador:hover .css-qc6sy-singleValue {
    color: #4E4E4E;
}

.buscador .css-qc6sy-singleValue {
    color: white;
}

.buscador:hover input {
    display: block;
}

.buscador:hover .fa {
    background: white;
    color: #0582D8;
}