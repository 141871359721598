@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.informacion-agente {
  @include flex-between-center;
  width: 100%;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  margin-top: 15px;
  padding: 25px;

  .left-container {
    @include flex-start-center;
    flex-wrap: wrap;
    width: 50%;
    height: 100%;
    align-content: center;
    &__title{ 
      width: 100%;
      font-weight: 600;
      font-size: 24px;
      color: #4E4E4E;
      margin-bottom: 5px;
    }
    &__name{ 
      width: 100%;
      font-weight: 300;
      font-size: 18px;
    }
  }

  .right-container {
    .line {
      text-align: right;
      color: #8F8F8F;
      margin-bottom: 3px;
      &:last-of-type{
        margin-bottom: 0;
      }
      &--bold {
        font-weight: 600;
      }
    }
  }
}