@mixin flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.modal {
  width: 250px;
  height: auto;
  background: white;
  position: absolute;
  top: 80px;
  right: 0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;

  &__input-container {
    @include flex-between-center;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #CCCCCC;

    &:last-of-type {
      border-bottom: none
    }

    .input-label {
      @include flex-start-center;
      justify-content: center;
      color: #121212;
      font-weight: 700;
      font-size: 13px;
      width: 35%;
      padding-left: 5%;
    }

    .select {
      width: 65%;
      padding: 0 20px;
      display: flex;
      justify-content: center;
      select{
        min-width: 130px;
        height: 30px;
        border: 1px solid white;
        font-weight: 700; 
        cursor: pointer;
        outline:none !important;
        text-align: center;
      }
    }

    .input {
      width: 65%;
      padding: 0 20px;
      
      input {
        border: none;
        height: 100%;
        outline: none;
        padding: 0;
        font-size: 13px;
        font-weight: 600;
      }

      &--periodo{
        display: flex;
        justify-content: space-between;
        padding: 0 5px;
        input{
          font-size: 12px;
        }
      }
    }
  
  }


  &__button {
    @include flex-center-center;
    width: 100%;
    height: 50px;
    background: #0582D8;
    padding: 8px 32px;
    border: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    color: white;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    &--disabled{
      background-color: #67b6ed;
    }
  }
}