.indicadores-filtro__encabezado {
    display: flex;
    justify-content: space-between;

    .reset-all {
        color: #0582D8;
        cursor: pointer;
        font-weight: 600;
    }
}
.indicadores-filtro__btn--disabled{
    background-color: #edc7297a !important;
    color:#12121245 !important;
    cursor: initial !important;
}
